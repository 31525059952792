<template>
    <nav id="custom_header">
        <ul>
            <li><router-link :to="{name: 'home'}" :class="active == 'home' ? 'active header-element' : 'header-element'">Startseite</router-link></li>
            <!--<li><router-link :to="{name: 'news'}" :class="active == 'news' ? 'active header-element' : ' header-element'">Neuigkeiten</router-link></li>-->
            <li @mouseenter="dropdown_states['aboutus'] = true" @mouseleave="dropdown_states['aboutus'] = false">
                <a :class="active == 'aboutus' ? 'active header-element' : 'header-element'" style="cursor: default">Der Verein</a>
                <ul class="origin-top-right absolute top-full left-0 w-40 py-2 rounded bg-white" v-show="dropdown_states['aboutus']">
                    <li>
                        <router-link :to="{name: 'hundmensch'}" class="flex py-2 px-4 font-size-16 font-weigth-600 header-element">Hund-Mensch Teams</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'vorstand'}" class="flex py-2 px-4 font-size-16 font-weigth-600 header-element">Vorstandschaft</router-link>
                    </li>
                </ul>
            </li>
            <li><router-link :to="{name: 'mitglied'}" :class="active == 'mitglied' ? 'active header-element' : ' header-element'">Mitglied werden</router-link></li>
            <li><router-link :to="{name: 'images'}" :class="active == 'images' ? 'active header-element' : 'header-element'">Bildergalerie</router-link></li>
            <li @mouseenter="dropdown_states['moreinfo'] = true" @mouseleave="dropdown_states['moreinfo'] = false">
                <a :class="active == 'moreinfo' ? 'active header-element' : 'header-element'" style="cursor: default">Weitere Infos</a>
                <ul class="origin-top-right absolute top-full left-0 w-40 py-2 rounded bg-white" v-show="dropdown_states['moreinfo']">
                    <li>
                        <router-link :to="{name: 'impressum'}" class="flex py-2 px-4 font-size-16 font-weigth-600 header-element">Impressum/Datenschutz</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'contact'}" class="flex py-2 px-4 font-size-16 font-weigth-600 header-element">Kontakt</router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>

<style>
nav {
    display: flex;
    flex-grow: 1;
    padding: 8px;
    background-color: white;
    z-index: 3;
}

ul {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

li {
    list-style: none;
    padding: 5px;
    position: relative;
}

.header-element {
    display: block;
    padding: 15px;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
    color: #333;
    letter-spacing: 0.2em;
    text-decoration: none;
    transition: color 200ms;
}
.header-element:hover {
    color: #ffffff;
    color: white;
    background: #333;
    transition: all 400ms ease;
}
.active {
    background-color: #121212;
    color: white;
}
.c-sticky {
    position: sticky;
    top: 0;
    width: 100%;
}

/* Navbar Ende */
</style>

<script>
export default {
    name: 'custom-header',
    props: {
        active: {
            String,
        },
    },
    mounted() {
        const header = document.getElementById('custom_header');
        const sticky_offset = header.offsetTop;

        window.onscroll = () => {
            if (window.pageYOffset > sticky_offset) {
                header.classList.add('c-sticky');
                header.style.position = null;
            } else {
                header.classList.remove('c-sticky');
                header.style.position = 'inherit';
            }
        };
    },
    data() {
        return {
            dropdown_states: {
                aboutus: false,
                moreinfo: false,
            },
        };
    },
};
</script>
