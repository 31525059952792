<template>
    <custom-header :active="'mitglied'" style="position: sticky"></custom-header>
    <main>
        <section class="background-mitgliedwerden flex items-center text-center"></section>
        <section class="flex flex-col justify-center items-center mx-20-vh" style="margin-bottom: 5vw">
            <form class="items-center flex-col gap-24 flex-wrap flex gap-4 w-full">
                <div class="flex flex-row gap-8 w-full">
                    <div class="w-full">
                        <div class="mb-2 flex flex-col gap-4">
                            <label for="fname" class="p-4 bg-gray text-white w-fit">Mitgliedantrag</label>
                            <a
                                class="btn"
                                style="background-color: #3b5b90; color: white"
                                href="https://happydogs-kupferzell.de/assets/pdf/Mitgliedsantrag_Happy_Dogs.pdf"
                                download
                            >
                                Download
                            </a>
                        </div>
                        <embed
                            src="https://happydogs-kupferzell.de/assets/pdf/Mitgliedsantrag_Happy_Dogs.pdf"
                            type="application/pdf"
                            width="100%"
                            style="min-height: 50vw"
                        />
                    </div>
                    <div class="w-full">
                        <div class="mb-2 flex flex-col gap-4">
                            <label for="fname" class="p-4 bg-gray text-white w-fit">Satzung</label>
                            <a
                                class="btn"
                                style="background-color: #3b5b90; color: white"
                                href="https://happydogs-kupferzell.de/assets/pdf/Satzung_Happy_Dogs.pdf"
                                download
                            >
                                Download
                            </a>
                        </div>
                        <embed src="https://happydogs-kupferzell.de/assets/pdf/Satzung_Happy_Dogs.pdf" type="application/pdf" width="100%" style="min-height: 50vw" />
                    </div>
                </div>
            </form>
        </section>
        <section class="flex flex-col justify-center items-start mx-20-vh bg-white p-4 rounded-xl" style="margin-bottom: 5vw">
            <span class="p-4 mb-4 bg-gray text-white w-fit">Mitgliedantrag Antrag</span>
            <span class="mb-4 text-black w-fit"
                >Du möchtest deinen Antrag nicht vor Ort sondern Online abgeben? <br />Kein Problem, lade deinen ausgefüllten Mitgliederantrag direkt hier hoch und wir
                melden uns bei dir.</span
            >
            <div class="bg-gray p-4 flex flex-row justify-center items-center">
                <div class="flex flex-col gap-2">
                    <input type="file" class="input-design text-white" ref="antrag" accept=".doc, .docx, .pdf" />
                    <span v-if="no_file" class="text-red w-fit" ref="no_file">Keine Datei ausgewählt, bitte wähle eine PDF Datei aus</span>
                </div>
                <button type="button" ref="upload_button" class="btn" style="background-color: #3b5b90; color: white; margin-left: 1vw" @click="upload">
                    <i v-if="uploading" class="fas fa-sync fa-spin mr-2 text-white"></i>
                    <i v-if="success" class="fas fa-check mr-2 text-white"></i>Abschicken
                </button>
            </div>
        </section>
    </main>
    <custom-footer></custom-footer>
</template>

<style>
.background-mitgliedwerden {
    background-image: url('@/assets/images/MitgliedWerdenSeiteHeaderohneschatten.png');
    background-repeat: no-repeat;
    height: 725px;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    z-index: 2;
}
</style>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';

export default {
    name: 'Home',
    components: {
        CustomHeader,
        CustomFooter,
    },
    data() {
        return {
            no_file: false,
            uploading: false,
            success: false,
        };
    },
    methods: {
        async upload() {
            let formData = new FormData();
            if (!this.$refs['antrag'].files[0]) {
                this.no_file = true;
                setTimeout(() => (this.no_file = false), 7500);
                return;
            }
            this.uploading = true;
            if (this.$refs['antrag'].files[0]) formData.append('antrag', this.$refs['antrag'].files[0]);
            const update = await fetch(`https://cdn.henrikdev.xyz/happy_dogs/v1/mitglied`, {
                method: 'POST',
                body: formData,
            });
            if (!update) {
                this.uploading = false;
                this.$refs['upload_button'].style['background-color'] = '#ff0000';
            } else {
                this.uploading = false;
                this.success = true;
                this.$refs['upload_button'].style['background-color'] = '#00ff00';
                setTimeout(() => {
                    this.success = false;
                    this.$refs['upload_button'].style['background-color'] = '#3b5b90';
                }, 7500);
            }
        },
        /*inputclick(event) {
            console.log(event);
            anime({
                targets: event.target,
                width: ['0%', '100%'],
                duration: 1000,
                borderBottom: '10px solid #ff4654',
                bottom: 0,
                opacity: 1,
                easing: 'easeInOutQuad',
            });
        },
        inputleave(event) {
            anime({
                targets: event.target,
                width: ['100%', '0%'],
                duration: 1000,
                borderBottom: '10px solid #ff4654',
                bottom: 0,
                opacity: 1,
                easing: 'easeInOutQuad',
            });
        },*/
    },
};
</script>
