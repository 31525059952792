<template>
    <custom-header :active="'home'" class="w-full"></custom-header>
    <main>
        <section class="background-main flex items-center text-center"></section>
        <section class="flex items-center text-center justify-center">
            <div class="flex flex-col gap-8 w-full mx-30-vh">
                <div class="m-h-25 grid_border p-12 bg-opacity h-full" :style="`background-image: url(${card.background}); background-size: cover`">
                    <div style="min-height: inherit">
                        <div class="flex flex-row items-center gap-4 mb-8">
                            <img :src="card.image" width="250" height="96" class="ml-2" />
                            <p class="text-3xl font-carter-one w-full">{{ card.title }}</p>
                        </div>
                        <div class="flex flex-col items-center text-center justify-center h-full">
                            <p class="text-xl font-carter-one" v-html="card.description"></p>
                        </div>
                    </div>
                </div>
                <!--<router-link :to="{name: 'news'}" class="btn" style="background-color: #3b5b90; color: white"> Zu den Neuigkeiten </router-link>-->
            </div>
        </section>
        <section>
            <img src="@/assets/images/bilduntenohneschatten.png" style="width: 100%" />
        </section>
    </main>
    <custom-footer></custom-footer>
</template>

<style>
.background-main {
    background-image: url('@/assets/images/startseiteobenohneschatten.png');
    background-repeat: no-repeat;
    height: 725px;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    z-index: 1;
}
.center-image-main {
    width: fit-content;
    position: absolute;
    left: 10vw;
    transform: translateY(-30%);
    color: white;
}
.grid_border {
    border-width: 5px;
    border-color: #4a7dac;
    border-radius: 1.5rem;
}
@media (max-width: 768px) {
    .center-image-main {
        width: fit-content;
        position: static;
        margin: auto;
    }
}
</style>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';

export default {
    name: 'Home',
    components: {
        CustomHeader,
        CustomFooter,
    },
    data() {
        return {
            card: {},
        };
    },
    async created() {
        const news = await (await fetch('https://happydogs-kupferzell.de/assets/dynamic_content/news.json')).json();
        this.card = {
            background: `https://happydogs-kupferzell.de/assets/images/${news[0].background}`,
            image: `https://happydogs-kupferzell.de/assets/images/${news[0].image}`,
            title: news[0].title,
            description: news[0].description,
        };
    },
    methods: {
        upperCase(text) {
            let return_text = '';
            const split_string = text.split('');
            for (const split of split_string) {
                if (split === split.toUpperCase() && split != ' ') return_text += `<small class='upper'>${split}</small>`;
                else return_text += split;
            }
            return return_text;
        },
    },
};
</script>
