<template>
    <custom-header style="position: sticky" :active="'moreinfo'"></custom-header>
    <main>
        <section class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto grid_border my-2 text-white" style="background: #435c73">
            <section class="flex text-start">
                <div class="ml-36 mt-24 mb-12">
                    <p class="text-3xl p-4 bg-gray text-white w-fit mb-2">Impressum</p>
                    <p class="text-lg">Dienstanbieter der Internet-Präsenz www.happydogs-kupferzell.de gemäß § 5 TMG</p>
                </div>
            </section>
            <section class="flex text-start flex-col flex-wrap">
                <div class="flex flex-col mb-8">
                    <p class="mb-2 text-lg">Happy Dogs e.V.</p>
                    <p class="mb-2 text-m text-white">Rudolf-Mößner-Strasse 8</p>
                    <p class="mb-2 text-m">74635 Kupferzell</p>
                </div>
                <div class="flex flex-col mb-8">
                    <p class="mb-4 p-2 bg-gray text-white w-fit text-lg">Vertreten durch</p>
                    <p class="mb-2 text-m">1. Vorsitzender: Kevin Treff</p>
                    <p class="mb-2 text-m">Rudolf-Mößner-Strasse 8</p>
                    <p class="mb-2 text-m">74635 Kupferzell</p>
                    <a class="mb-2 text-m" href="mailto:info@happydogs-kupferzell.de">info@happydogs-kupferzell.de</a>
                </div>
                <div class="flex flex-col mb-12">
                    <p class="mb-4 p-2 bg-gray text-white w-fit text-lg">Eintragung im Vereinsregister</p>
                    <p class="mb-2 text-m">Registergericht: Vereinsgericht Amtsgericht Stuttgart</p>
                    <p class="mb-2 text-m">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: K. Treff</p>
                </div>
                <div class="flex flex-col mb-4 items-center">
                    <p class="mb-4 text-sm p-2 bg-gray text-white w-fit">
                        - Alle Inhalte dieser Website, einschließlich der gezeigten Bilder und Texte, sind urheberrechtlich geschützt. Vervielfältigung, Weitergabe und
                        Druck sind nur mit Einverständnis des Urhebers erlaubt. -
                    </p>
                </div>
                <div class="flex flex-col mb-4">
                    <p class="mb-2 p-2 bg-gray text-white w-fit text-2xl">Disclaimer</p>
                </div>
                <div class="flex flex-col mb-4">
                    <p class="mb-4 p-2 bg-gray text-white w-fit">Haftung für Inhalte</p>
                    <p class="mb-4 text-s">
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
                        10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                        Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
                        nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                        Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </p>
                </div>
                <div class="flex flex-col mb-4">
                    <p class="mb-4 p-2 bg-gray text-white w-fit text-lg">Haftung für Links</p>
                    <p class="mb-4 text-s">
                        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
                        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
                        Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                    </p>
                </div>
                <div class="flex flex-col mb-4">
                    <p class="mb-4 p-2 bg-gray text-white w-fit text-lg">Urheberrecht</p>
                    <p class="mb-4 text-s">
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
                        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
                        Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte
                        auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                        gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                        Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                    </p>
                </div>
            </section>
            <section class="flex text-start">
                <div class="ml-36 mb-12">
                    <p class="mb-2 p-2 bg-gray text-white w-fit text-3xl">Datenschutz</p>
                    <p class="text-5">Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst.</p>
                </div>
            </section>
            <div class="ml-36">
                <div class="mb-4 flex flex-col">
                    <label for="fname" class="p-2 bg-gray text-white w-fit text-lg mb-4">Datenschutzinformationen</label><br />
                    <a class="btn" style="background-color: #95b8f0; color: white" href="https://happydogs-kupferzell.de/assets/pdf/Datenschutz_Happy_Dogs.pdf" download>
                        Download
                    </a>
                </div>
                <embed src="https://happydogs-kupferzell.de/assets/pdf/Datenschutz_Happy_Dogs.pdf" type="application/pdf" width="100%" style="min-height: 50vw" />
            </div>
        </section>
    </main>
    <custom-footer></custom-footer>
</template>

<style>
.grid_border {
    border-width: 5px;
    border-color: #4a7dac;
    border-radius: 1.5rem;
}
</style>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';
export default {
    name: 'Home',
    components: {
        CustomHeader,
        CustomFooter,
    },
};
</script>
