<template class="h-screen">
    <custom-header :active="'moreinfo'" style="position: sticky"></custom-header>
    <main class="h-full">
        <section class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto grid_border my-2 text-white h-full items-center justify-center" style="background: #435c73">
            <section class="flex text-start">
                <div class="ml-36 mt-24 mb-12">
                    <p class="text-3xl p-4 bg-gray text-white w-fit mb-2">Kontakt</p>
                </div>
            </section>
            <section class="flex text-start flex-col flex-wrap">
                <div class="flex flex-col mb-8">
                    <p class="mb-2 text-lg">Happy Dogs e.V.</p>
                    <p class="mb-2 text-m text-white">Rudolf-Mößner-Strasse 8</p>
                    <p class="mb-2 text-m">74635 Kupferzell</p>
                    <a class="mb-2 text-m" href="mailto:info@happydogs-kupferzell.de">info@happydogs-kupferzell.de</a>
                </div>
            </section>
        </section>
    </main>
    <custom-footer style="position: fixed; width: 100%; bottom: 0"></custom-footer>
</template>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';
export default {
    name: 'Home',
    components: {
        CustomHeader,
        CustomFooter,
    },
};
</script>
