<template>
    <custom-header :active="'images'" style="position: sticky"></custom-header>
    <main>
        <section class="flex items-center text-center flex-wrap mt-16 flex-direction-column" style="height: 100vh">
            <div class="slideshow-container">
                <div class="mySlides fade">
                    <div class="numbertext p-4 bg-gray text-white">{{ index + 1 }} / {{ images.length }}</div>
                    <div v-for="(image, i) of images" :key="image"><img :src="image" v-show="i == index" style="max-height: 75vh" /></div>
                    <div class="text">{{ getCaption() }}</div>
                </div>
                <a class="prev" v-show="index - 1 >= 0" @click="beforePage()">&#10094;</a>
                <a class="next" v-show="index + 1 < images.length" @click="nextPage()">&#10095;</a>
            </div>
        </section>
    </main>
    <custom-footer></custom-footer>
</template>

<style>
.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.prev {
    left: 0;
}

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}

.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active_img,
.dot:hover {
    background-color: #717171;
}
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}
</style>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';

export default {
    name: 'Home',
    components: {
        CustomHeader,
        CustomFooter,
    },
    data() {
        return {
            images: [],
            index: 0,
        };
    },
    async created() {
        const images = await (await fetch('https://happydogs-kupferzell.de/assets/dynamic_content/galerie.json')).json();
        this.images = images.map(i => `https://happydogs-kupferzell.de/assets/images/${i}`);
    },
    methods: {
        nextPage() {
            if (this.index + 1 >= this.images.length) return;
            this.index += 1;
        },
        beforePage() {
            if (this.index - 1 < 0) return;
            this.index -= 1;
        },
        getCaption() {
            return this.images[this.index].caption ?? '';
        },
    },
};
</script>
