<template>
    <footer id="custom_footer" :class="sticky ? 'f-sticky' : ''">
        <div class="footer">
            <li><a href="mailto:info@happydogs-kupferzell.de" class="footer-element">info@happydogs-kupferzell.de</a></li>
            <li><router-link :to="{name: 'impressum'}" class="footer-element">Impressum/Datenschutz</router-link></li>
            <li><router-link :to="{name: 'contact'}" class="footer-element">Kontakt</router-link></li>
            <!--<li><a href="https://goo.gl/maps/v6qFoagUtm18Twk2A">Anfahrt zum Platz</a></li>-->
            <li><router-link :to="{name: 'mitglied'}" :class="active == 'mitglied' ? 'active footer-element' : 'footer-element'">Mitgliedsantrag</router-link></li>
        </div>
        <p class="footer">Webseite © Happy DOGS Kupferzell e.V.</p>
    </footer>
</template>

<style>
.footer {
    display: flex;
    flex-grow: 1;
    padding: 8px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: white;
}
ul {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
}

li {
    list-style: none;
    padding: 5px;
}

.footer-element {
    display: block;
    padding: 15px;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    color: #333;
    letter-spacing: 0.2em;
    text-decoration: none;
    transition: color 200ms;
}
.footer-element:hover {
    color: #ffffff;
    color: white;
    background: #333;
    transition: all 400ms ease;
}

.active {
    background-color: #121212;
    color: white;
}

.f-sticky {
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* Navbar Ende */
</style>

<script>
export default {
    name: 'custom-footer',
    props: {
        sticky: Boolean,
    },
    mounted() {
        const footer = document.getElementById('custom_footer');

        console.log(window.innerHeight, Math.round(window.scrollY), document.body.offsetHeight);
        if (window.innerHeight + Math.round(window.scrollY) >= document.offsetHeight) {
            footer.classList.add('f-sticky');
        }
    },
};
</script>
