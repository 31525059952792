<template>
    <custom-header :active="'aboutus'" style="position: sticky"></custom-header>
    <main>
        <section class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div class="ml-36 mt-24 mb-24">
                <p class="text-3xl p-4 bg-gray text-white w-fit">Vorstand</p>
            </div>
        </section>
        <section class="flex flex-col justify-center items-center mx-20-vh" style="margin-bottom: 5vw; margin-top: 2.5vw">
            <div class="grid grid-main gap-8 w-full" style="grid-template-rows: auto; grid-template-columns: repeat(auto-fit, minmax(400px, 1fr))">
                <div v-for="element of matchings" :key="element" class="flex items-center text-center flex-col">
                    <img class="flex flex-direction-column mb-2" :src="element.image" style="height: 500px; width: fit-content" />
                    <div class="flex flex-col gap-2 items-center">
                        <p class="bg-gray text-white p-2 w-fit">{{ element.title }}</p>
                        <p class="bg-gray text-white p-2 w-fit">{{ element.description }}</p>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <custom-footer></custom-footer>
</template>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';

export default {
    name: 'vorstand',
    components: {
        CustomHeader,
        CustomFooter,
    },
    data() {
        return {
            matchings: [],
        };
    },
    async created() {
        const vorstand = await (await fetch('https://happydogs-kupferzell.de/assets/dynamic_content/vorstand.json')).json();
        this.matchings = vorstand.map(i => {
            return {
                ...i,
                image: `https://happydogs-kupferzell.de/assets/images/${i.image}`,
            };
        });
    },
    methods: {
        upperCase(text) {
            let return_text = '';
            const split_string = text.split('');
            for (const split of split_string) {
                if (split === split.toUpperCase() && split != ' ') return_text += `<small class='upper'>${split}</small>`;
                else return_text += split;
            }
            return return_text;
        },
    },
};
</script>
